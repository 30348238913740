<template>
    <section
        :data-aos="data.animation"
        data-aos-duration="1500"
        class="section quote"
        :class="[data.custom_class]"
        :style="{backgroundImage: 'url(' + data.slides[current].image + ')' }"
    >
        <transition-group tag="div" :name="transitionName" class="slides-group">
            <div v-if="show" :key="current" class="main-slider__slide" :class="data.slides[current].title">
                <div class="container">
                    <div class="columns main-slider__columns">
                        <div class="column is-6 main-slider__column">
                            <div class="main-slider__text">
                                <p class="is-size-4">{{ data.slides[current].text }}</p>
                                <span><a :href="data.slides[current].link_url" @click.prevent="navigateTo('/' + data.slides[current].link_url)">{{ data.slides[current].link_title }}</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
        <div class="container">
            <div class="columns is-vcentered">
                <div class="quote__buttons">
                    <a @click.prevent="slide(1)"><img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg"></a>
                    <a @click.prevent="slide(-1)"><img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg"></a>
                </div>
            </div>
        </div>
            <!-- <div class="container">
                <div class="columns is-vcentered">
                    <div class="column is-10 quote__text-box" v-if="data.slides" :key="current">
                        <p class="quote__text">{{ data.slides[current].text }}</p>
                    </div>
                    <div class="quote__buttons">
                        <a @click.prevent="slide(1)"><img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg"></a>
                        <a @click.prevent="slide(-1)"><img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg"></a>
                    </div>
                </div>
            </div> -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            current: 0,
            show: false,
            direction: 1,
            transitionName: "fade"
        }
    },
    methods: {
        slide(dir) {
            this.direction = dir;
            dir === 1 ? (this.transitionName = "slide-next") : (this.transitionName = "slide-prev")
            var len = this.data.slides.length
            this.current = (this.current + dir % len + len) % len
        },
        navigateTo(url) {
            const publicPath = "/" + this.$store.getters.getPublicPath;
            const locale = this.$store.getters.getLocale;
            if (url != "#") {
                this.$router.push(url);
                this.burger = false;
            }
        },
    },
    // created() {
    //     setInterval(() => {
    //         this.transitionName = "slide-next"
    //         var len = this.data.slides.length
    //         this.current = (this.current + 1 % len + len) % len
    //         console.log(this.current)
    //     }, 3400),
    //     this.show = true
    // }
}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"

.quote
    background-repeat: no-repeat
    background-size: 160px
    background-position: center center
    +touch
        padding: $big $medium !important
    +mobile
        display: flex
        flex-direction: column
        text-align: center
    &--blue
        background-color: $blue
        color: $white
        padding: 80px $medium !important
        margin-bottom: $medium
        background-position: 100px center
        +widescreen-only
            background-position: 50px center
        +desktop-only
            background-position: 50px center
        +touch
            background-position: center
            padding: 40px $medium !important
        & .columns
            justify-content: flex-end
            +touch
                justify-content: center
                text-align: center
    &--white
        color: $light-blue
    &--grey
        background-color: $beige
        padding-top: 50px !important
        background-position: center 70px
    &--center
        text-align: center
        justify-content: center
        & .columns
            justify-content: center
    &--extra-padding
        +huge
            padding: 70px 0px
    &--extra-m-top
        .quote__text-box
            margin-top: 3rem
    &--extra-p-top
        .quote__text-box
            padding-top: 4rem !important
            +mobile
                padding-top: 2rem
                padding-bottom: 2rem
    &__text
        font-size: 1.5rem
        font-weight: 500
        +touch
            font-size: 1.4rem
        +mobile
            font-size: 1.3rem
    &__mark
        max-width: 100px
        margin: 0 auto
    &--bottom-0
        margin-bottom: 0
    &__buttons
        position: absolute
        left: 10%
        bottom: -68px
        z-index: 900
        +touch
            bottom: 50px
        a
            display: inline-block
            padding: 13px 10px 10px 10px
            background-color: $dark-blue
            margin-right: 1px
            img
                width: 40px
            &:nth-child(2)
                img
                    transform: rotate(180deg)
.fade-enter-active
    transition: opacity 1s
.fade-enter
    opacity: 0
.slide-next-enter-active,
.slide-next-leave-active
    transition: transform 1s ease-in-out, opacity 1.6s ease-in-out
.slide-next-enter
    transform: translate(30%)
    opacity: 0
.slide-next-leave-to
    transform: translate(-100%)
    opacity: 0
.slide-prev-enter-active,
.slide-prev-leave-active
    transition: transform 1s ease-in-out, opacity .4s ease-in-out
.slide-prev-enter
    transform: translate(-100%)
    opacity: 1
.slide-prev-leave-to
    transform: translate(100%)
    opacity: 0
</style>
