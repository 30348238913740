<template>
<div id="app">
	<the-header />
	<main class="main">
		<aside class="aside-nav">
			<ul class="aside-nav__list">
				<li v-for="(menuItem, menuKey) in mainMenu"
				    :key="menuKey">
					<router-link :to="'/'+ menuItem.url"
					             @click.prevent="menuItem.children ? '' : navigateTo('/'+menuItem.url)"
					             v-html="menuItem.title"></router-link>
				</li>
			</ul>
		</aside>
		<transition name="fade"
		            mode="out-in">
			<router-view :key="$route.params.id" />
		</transition>
	</main>
	<cookies />
	<the-footer />
</div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import Cookies from '@/components/Cookies.vue'
import AOS from 'aos'

export default {
	components: {
		'the-header': TheHeader,
		'the-footer': TheFooter,
		'cookies': Cookies,
	},
	metaInfo() {
		return {
			titleTemplate: this.seoTitleTemplate,
			title: this.title,
			link: [{
				rel: 'icon',
				href: this.optionsFavicon
			}],
			meta: [{
				name: "robots",
				content: this.robotsIndex
			}]
		}
	},
	computed: {
		page() {
			let page = this.$store.getters.getPageByPath(this.$route.path)
			if (page === undefined) {
				this.$router.push({
					name: 'error404'
				})
			} else {
				return this.$store.getters.getPageByPath(this.$route.path)
			}
		},
		mainMenu() {
			return this.$store.getters.getMainMenu;
		},
		menuLocale() {
			return this.locale != this.$store.getters.getOptions.default_language ?
				this.locale + "/" :
				"";
		},
		title() {
			if (this.page !== undefined) {
				return this.page.title
			} else {
				return ''
			}
		},
		seoTitleTemplate() {
			return this.$store.getters.getSeoTitleTemplate
		},
		optionsFavicon() {
			return this.$store.getters.getOptionsFavicon
		},
		robotsIndex() {
			return this.$store.getters.getSeoIndex
		}
	},
	methods: {
		navigateTo(url) {
			const publicPath = "/" + this.$store.getters.getPublicPath;
			const locale = this.$store.getters.getLocale;
			if (url != "#") {
				this.$router.push(url);
				this.burger = false;
			}
		}
	},
	created() {
		AOS.init({
			once: true
		});
	}
}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap&subset=latin-ext')

#app
    overflow: hidden
    font-family: 'Roboto', sans-serif
    font-weight: 300

.main
	display: flex
	width: 100%
	flex-direction: row
	padding-top: 100px
	box-sizing: border-box

.main,
.aside-nav
	box-sizing: border-box

.aside-nav
	position: fixed
	width: 100px
	padding: 20px 10px 20px
	height: calc(100vh - 100px)
	z-index: 10
	background-color: $white
	+mobile
		display: none !important

	&,
	&__list
		display: flex
		flex-direction: column

	&__list
		text-align: center
		height: 100%
		max-height: 1000px
		display: flex
		justify-content: space-between

		& li
			display: block
			transform: rotate(90deg)
			margin: 30px 0px

			&:first-child
				margin-top: 12.5px

		& a
			text-decoration: none
			text-transform: uppercase
			font-weight: 300
			color: #4a4a4a
			transition: color .3s ease-in-out
			font-size: .875rem

			&:hover
				color: $light-blue

.router-link-exact-active
    color: #2279df !important

@media screen and (max-height: 700px)
    .aside-nav
        &__list
            li
                margin: 10px 0
@media screen and (max-height: 600px)
    .aside-nav
        display: none !important

</style>
