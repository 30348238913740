<template>
  <div class="root">
    <flexible-content/>
    <section class="section view-single" v-if="page && page.content">
      <div class="container" v-if="page.thumbnail">
        <div class="columns is-centered">
          <div class="column is-10">
            <div class="content image-wrapper">
              <figure class="image">
                <img :src="page.thumbnail" :alt="page.title">
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-10">
            <div class="content">
              <h1 class="title" v-html="page.title"></h1>
              <div v-html="page.content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FlexibleContent from "@/components/FlexibleContent.vue";
export default {
    components: {
        "flexible-content": FlexibleContent
    },
    computed: {
        locale() {
            return this.$store.getters.getLocale;
        },
        page() {
            return this.$store.getters.getPageByPath(this.$route.path);
            },
        publicPath() {
            return this.$store.getters.getPublicPath;
        }
        }
};
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"

.root
    width: 100%

.page-content
    +mobile
        padding-left: 0
@media screen and (max-height: 600px)
    .page-content
        padding-left: 0
</style>
